<template>
  <div v-if="promotions">
    <section :class="wrapperClass" v-for="(promotion, i) in promotions" :key="i">
      <div class="dosiin-event-page best-promotion">
        <div class="event-info-page product-discount">
          <div class="brand-item_description brand-item">
            <div class="brand-item_img-wrapper">
              <a
                :href="_brandUrl(promotion.company_data.seo_name)"
                :title="promotion.name"
              >
                <BrandLogo :brand="promotion.company_data" :width="44" :height="44" />
              </a>
            </div>
            <div class="brand-item_info">
              <a
                :href="_brandUrl(promotion.company_data.seo_name)"
                :title="promotion.company_data.company"
              >
                <h5 class="brand-item_title" v-text="promotion.company_data.company"></h5>
              </a>
              <dl class="brand-item_social">
                <div class="quantity-product">
                  <b>{{ promotion.company_data.products_count }} </b> sản phẩm
                </div>
              </dl>
            </div>
          </div>

          <div class="promoWithBrand d-flex align-items-center">
            <i class="dsi dsi-fill-coupons"></i>
            <span v-text="promotion.name"></span>
          </div>

          <ul>
            <i class="dsi dsi-clock"></i>
            <li>
              Từ <span>{{ _momentDateMonthYear(promotion.time_start) }}</span> đến
              <span>{{ _momentDateMonthYear(promotion.time_end) }}</span>
            </li>
          </ul>

          <div class="line-time-count-event"></div>

          <div
            class="bestsellers-list-item_content"
            v-for="(product, index2) in promotion.products"
            :key="index2"
          >
            <router-link
              class="list-item_img bestsellers-list-item_img dosiin_d-block"
              :to="_productUrl(product)"
              :title="product.product"
            >
              <img
                v-lazy="
                  _productThumbnailSticker(product.main_pair, product.sticker.main_pair)
                "
                width="73"
                height="73"
                :alt="product.product"
                :title="product.product"
              />
            </router-link>
            <dl class="list-item_summary">
              <dt>
                <router-link
                  class="list-item_brand"
                  :to="_brandUrl(product.seo_name_company)"
                  :title="product.company_name"
                  v-text="product.company_name"
                ></router-link>
              </dt>
              <div class="list-item_sale">
                <div id="item" v-if="product.list_price > product.price">
                  <dd class="inline-bl list-item_sale_origin">
                    {{ numberFormat(product.list_price) }}₫
                  </dd>
                  <dd class="inline-bl list-item_sale_discount">
                    -{{ discountPrc(product) }}%
                  </dd>
                </div>
              </div>
              <dd class="list-item_price">{{ numberFormat(product.price) }}₫</dd>
            </dl>
          </div>

          <div class="seemore-btn promotion" v-if="promotion.products.length != 0">
            <router-link
              class="primary-button button"
              :to="{
                name: 'promotion-detail',
                query: {
                  company_id: promotion.company_data.company_id,
                  promotion_id: promotion.id,
                },
              }"
              title="Xem khuyến mãi"
            >
              <span class="primary-link_text">Xem khuyến mãi</span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </router-link>
          </div>
        </div>

        <div class="event-right-page">
          <div class="event-item-page">
            <a :href="_brandUrl(promotion.company_data.seo_name)" :title="promotion.name">
              <div class="img-dosiin-event">
                <img
                  v-lazy="{
                    src: _bannerImage(promotion.company_data.banners_main),
                    loading: _bannerDefault,
                    error: _bannerDefault,
                  }"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { toRefs, ref, watch } from "vue";

export default {
  components: {},
  props: {
    wrapperClass: {
      type: String,
      default: "",
    },
    promotions: {
      type: Array,
      require: true,
      default: [],
    },
  },
  setup(props) {
    return {
      ...toRefs(props),
    };
  },
};
</script>
