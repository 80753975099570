<template>
  <div class="dosiin_page-max-width dosiin_padding-header-cat-top dosiin_page-min-height">
    <LoadingComponent v-if="state.loading" />
    <div v-else>
      <div class="bread-crumb-best-promotion">
        <div class="title-page d-flex align-items-center justify-content-between">
          <div class="title-all-categories">Khuyến mãi sốc</div>
        </div>
      </div>
      <BrandsSuggestHorizon
        wrapperClass="page-section brand-promotion"
        title="Thương hiệu đang khuyến mãi"
        gridClass="grid-20"
        :brands="state.promotions_brand"
        :options="{
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 9,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        }"
      />

      <div ref="promotionsBox">
        <BrandPromotionWithProduct
          wrapperClass="page-section brand-best-pomotion"
          :promotions="state.promotions_shock"
        />
      </div>

      <section class="page-section" v-if="state.totalPage > 1">
        <div class="pagination-bottom" id="pagination-bottom">
          <Pagination
            v-if="state.totalPage > 1 && !state.hidePagination"
            v-model="state.page"
            :pages="state.totalPage"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import Pagination from "@/components/pagination/Pagination";
import BrandsSuggestHorizon from "@/components/sliders/BrandsSuggestHorizon";
import BrandPromotionWithProduct from "@/components/brand/BrandPromotionWithProduct";

export default {
  components: {
    Pagination,
    BrandsSuggestHorizon,
    BrandPromotionWithProduct,
  },
  setup() {
    const state = inject("state");
    const promotionsBox = inject("promotionsBox");
    const router = useRouter();
    const route = useRoute();

    return {
      state,
      route,
      router,
      promotionsBox,
    };
  },
};
</script>
