<template>
  <div>
    <BackHeader>
      <template v-slot:center>
        <h2 class="text-grey-lg title-header-category">
          {{ state.title }}
        </h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>
    <LoadingComponent v-if="state.loading" />
    <div v-else>
      <section class="page-section brand_on-promo mostSearchBrand overflow">
        <div class="header-section">
          <h2 class="header-section-left-side section-title">Khuyến mãi sốc</h2>
        </div>
        <SkeletonBrandsSale v-if="state.promotions_brand.length == 0" />
        <Swiper
          v-else
          class="brand-list dosiin_modal-brand-swiper"
          :slidesPerView="5"
          :observer="true"
          :observeParents="true"
          :observeSlideChildren="true"
          :spaceBetween="9"
        >
          <Swiper-slide
            v-for="(brand, i) in state.promotions_brand"
            :key="i"
            class="brand-modal_item"
          >
            <router-link :to="_brandUrl(brand.seo_name)" :title="brand.name">
              <div class="brand_logo">
                <BrandLogo
                  :brand="brand"
                  :width="62"
                  :height="62"
                  imageClass="brand-logo_img"
                />
              </div>
              <h5 class="brand-name truncate" v-text="brand.company"></h5>
            </router-link>
          </Swiper-slide>
        </Swiper>
      </section>

      <div ref="promotionsBox">
        <section
          class="page-section brandPromoCate"
          v-for="(promotion, i) in state.promotions_shock"
          :key="i"
        >
          <div class="brand-suggested-brand-item_wrapper brand-item_img-wrapper">
            <div class="brand-item_header">
              <a
                class="dosiin_d-block"
                :href="_brandUrl(promotion.company_data.seo_name)"
                :title="promotion.name"
              >
                <div class="brand-item_banner-wrapper">
                  <div class="brand-item_img-overlay">
                    <img
                      v-lazy="{
                        src: _bannerImage(promotion.company_data.banners_main),
                        loading: _bannerDefault,
                        error: _bannerDefault,
                      }"
                      width="339"
                      height="190"
                      class="brand-list-item_img"
                    />
                  </div>
                </div>
              </a>
              <div class="brand-item">
                <div class="brand-item_img-wrapper">
                  <BrandLogo
                    :brand="promotion.company_data"
                    :width="40"
                    :height="40"
                    imageClass="brand-top-selling-item_img"
                  />
                </div>
                <div class="brand-item_title">
                  <a
                    :href="_brandUrl(promotion.company_data.seo_name)"
                    :title="promotion.name"
                  >
                    <h5
                      class="brand-item_title dosiin_truncate-title-brand"
                      v-text="promotion.company_data.company"
                    ></h5>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="promoWithBrand d-flex align-items-center">
            <i class="dsi dsi-act-fill-coupons"></i>
            <router-link
              :to="{
                name: 'promotion-detail',
                query: {
                  company_id: promotion.company_data.company_id,
                  promotion_id: promotion.id,
                },
              }"
              :title="promotion.name"
              v-if="promotion.products.length != 0"
            >
              <span v-text="promotion.name"></span>
            </router-link>
            <span v-else v-text="promotion.name"></span>
          </div>

          <div class="product-list">
            <div
              class="list-item product-list-item"
              v-for="(product, index2) in promotion.products"
              :key="index2"
            >
              <router-link
                class="list-item_img bestsellers-list-item_img"
                :to="_productUrl(product)"
                :title="product.product"
              >
                <img
                  v-lazy="
                    _productThumbnailSticker(product.main_pair, product.sticker.main_pair)
                  "
                  width="68"
                  height="68"
                  :alt="product.product"
                  :title="product.product"
                />
              </router-link>
              <dl class="list-item_summary">
                <dt>
                  <router-link
                    class="list-item_brand"
                    :to="_brandUrl(product.seo_name_company)"
                    :title="product.company_name"
                    v-text="product.company_name"
                  ></router-link>
                </dt>
                <dd class="list-item_name">
                  <router-link
                    :to="_productUrl(product)"
                    :title="product.product"
                    v-text="product.product"
                  ></router-link>
                </dd>
                <div class="list-item_sale product-list-item_price_wrapper">
                  <dd class="list-item_price">{{ numberFormat(product.price) }}₫</dd>
                  <template v-if="product.list_price > product.price">
                    <dd class="inline-bl list-item_sale_origin">
                      {{ numberFormat(product.list_price) }}₫
                    </dd>
                    <dd class="inline-bl list-item_sale_discount">
                      -{{ discountPrc(product) }}%
                    </dd>
                  </template>
                </div>
              </dl>
            </div>

            <div class="seemore-btn" v-if="promotion.products.length != 0">
              <router-link
                class="primary-button button"
                :to="{
                  name: 'promotion-detail',
                  query: {
                    company_id: promotion.company_data.company_id,
                    promotion_id: promotion.id,
                  },
                }"
                title="Xem khuyến mãi"
              >
                <span class="primary-link_text">Xem khuyến mãi</span>
                <i class="dsi dsi-act-arrow-forward"></i>
              </router-link>
            </div>
          </div>
        </section>
      </div>

      <section class="page-section" v-if="state.totalPage > 1">
        <div class="pagination-bottom" id="pagination-bottom">
          <Pagination
            v-if="state.totalPage > 1 && !state.hidePagination"
            v-model="state.page"
            :pages="state.totalPage"
            @update:modelValue="getPromotionsShock"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import BackHeader from "@/components/common/BackHeader";
import { useRoute, useRouter } from "vue-router";
import Pagination from "@/components/pagination/Pagination";
import SkeletonBrandsSale from "@/components/skeletons/sliders/BrandsSale";

export default {
  components: {
    BackHeader,
    Pagination,
    SkeletonBrandsSale,
  },
  setup() {
    const state = inject("state");
    const getPromotionsShock = inject("getPromotionsShock");
    const promotionsBox = inject("promotionsBox");
    const router = useRouter();
    const route = useRoute();

    return {
      state,
      route,
      router,
      getPromotionsShock,
      promotionsBox,
    };
  },
};
</script>
<style>
.brand_on-promo .brand-list .brand-logo_img {
  width: 62px !important;
  height: 62px !important;
}
</style>
