<template>
  <div
    class="swiper swiper-initialized swiper-horizontal swiper-pointer-events brand-list dosiin_modal-brand-swiper"
  >
    <div class="swiper-wrapper">
      <div
        class="swiper-slide swiper-slide-active overflow"
        style="width: 102px; border-radius: 8px; margin-right: 9px"
        v-for="i in 6"
        :key="i"
      >
        <Skeletor width="102px" height="108px" style="border-radius: 8px" />
      </div>
    </div>
  </div>
</template>
