<template>
  <PromotionsShockMB v-if="layout == 4" />
  <PromotionsShockPC v-else />
</template>

<script>
import PromotionsShockMB from "@/pages/mb/PromotionsShock";
import PromotionsShockPC from "@/pages/pc/PromotionsShock";
import PromotionServices from "@/services/promotionsshock";
import { ref, provide, reactive } from "vue";

export default {
  components: {
    PromotionsShockMB,
    PromotionsShockPC,
  },
  emits: ["totalBrands"],
  setup(props, { emit }) {
    const pagination = ref({});
    const promotionsBox = ref(null);

    const state = reactive({
      promotions_shock: [],
      promotions_brand: [],
      title: "Khuyến mãi sốc",
      loading: true,
      page: 1,
      totalPage: 0,
      hidePagination: props.hidePagination,
    });

    getPromotionsShock();

    async function getPromotionsShock() {
      const response = await PromotionServices.fetchPromotionsShock({
        page: state.page,
      });
      if (response && response.data) {
        state.promotions_shock = response.data.promotion_shock;
        state.promotions_brand = response.data.promotion_companies;

        pagination.value = response.data.params;
        state.totalPage = Math.ceil(
          pagination.value.total_items / pagination.value.items_per_page
        );
        state.page = pagination.value.page;
        state.loading = false;

        emit("totalBrands", pagination.value.total_items);
      }
    }

    provide("state", state);
    provide("promotionsBox", promotionsBox);
    provide("getPromotionsShock", getPromotionsShock);
  },
};
</script>
